import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Octicon, { Play } from "@primer/octicons-react"
import SEO from "../components/seo"
import CloseButton from "../components/close-button"
import Image from "../components/image"

import Pergament from "../images/pergament.jpg"

const GameFrame = (props) => <div css={css`
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
    padding-top: ${props.height + "px"};
  }
`}>
  <iframe css={css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  `} src={props.src} title={props.title} allowFullScreen />
</div>

class Game extends React.Component {
  constructor (props) {
    super(props)
    this.state = { shown: false }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick () {
    this.setState(prev => ({
      shown: !prev.shown
    }))
  }

  render () {
    const frame = (
      <GameFrame height={this.props.height || "600"} src={this.props.src} title={this.props.title} />
    )

    const thumbnail = (
      <div css={css`
        position: relative;
      `}>
        <button css={css`
          position: absolute;
          top: 37.5%;
          left: 35%;
          z-index: 1;

          border: 0;
          appearance: none;
          cursor: pointer;

          text-align: center;
          width: 30%;
          height: 25%;
          min-width: 250px;
          min-height: 125px;

          font-family: "Friz Quadrata W01", serif;
          font-variant: small-caps;
          font-size: 32px;
          line-height: 1.3;

          box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
          background: url(${Pergament});
          background-size: cover;

          @media (max-width: 60rem) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          @media (min-width: 60rem) {
            transition: box-shadow .15s ease-in-out, transform .15s ease-in-out;

            &:hover,
            &:focus {
              box-shadow: 0 0 75px rgba(0, 0, 0, 0.75);
              transform: translateY(-10px);
            }
          }
        `} onClick={this.handleClick}>
          <Octicon icon={Play} size="large" />
          <br/>Spiel starten
        </button>
        <Image filename={this.props.thumb} />
      </div>
    )

    const mobileLink = (
      <>
        <a href={this.props.src} target="_blank" rel="noopener noreferrer">
          <Image filename={this.props.thumb} />
        </a>
        <p>Dieses Spiel ist für die Benutzung an einem Desktop-Computer ausgelegt.</p>
      </>
    )

    return (
      <>
        <h2>{this.props.name}</h2>
        {this.props.desc && <div dangerouslySetInnerHTML={{ __html: this.props.desc }} />}
        {this.state.shown ? frame : typeof window !== `undefined` && window.matchMedia(`(min-width: 40rem)`).matches ? thumbnail : mobileLink}
      </>
    )
  }
}

export default ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title="Spiele" />
      <CloseButton />
      <h1 className="page-title">Spiele</h1>
      {posts.map(({ node }) => (
        <div key={node.id} css={css`
          & + & {
            margin-top: 2rem;
            padding-top: 2rem;
            // border-top: 2px solid #8a784c;
          }
        `}>
          <Game
            name={node.frontmatter.name}
            src={node.frontmatter.embed_source}
            title={node.frontmatter.short_description}
            desc={node.html}
            height={node.frontmatter.embed_height}
            thumb={node.frontmatter.thumbnail.relativePath}
          />
        </div>
      ))}
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "\/spiele/" } },
      sort: { fields: [frontmatter___priority], order: ASC },
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            name
            short_description
            embed_source
            embed_height
            thumbnail {
              relativePath
            }
          }
          fields {
            slug
          }
          html
        }
      }
    }
  }
`